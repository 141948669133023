@import url('https://fonts.googleapis.com/css2?family=Asset&family=Londrina+Shadow&family=Major+Mono+Display&family=Monoton&family=Mrs+Sheppards&family=Rowdies:wght@700&family=Russo+One&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-family: 'Rowdies', cursive;

    font-size: 2.2em;
    
    text-shadow: 3px 3px black;
    background-image: url(/public/title.webp);
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    
   
    margin: 10px 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
